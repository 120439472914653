import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell fa quasi 10 cm de diàmetre, hemisfèric al principi, convex per passar a pla. És de color groguenc viscós tornant-se marró torrat rogenc quan es seca. Té tubs igual de llargs que la gruixa del capell i de color groguencs o marró brut amb porus poligonals que deixen sortir una espècie de lletrada en forma de gotes de color groguenc-rogenc. Les espores són groc olivàcies en massa, fusiformes, de 9-11 x 4,5-5 micres. El peu és cilíndric, més gruixut en el terç inferior i atenuat a la base on presenta un miceli blanc amb tons rosats. Presenta granulacions fines. És de color blanquinós cap a baix i més groguenc cap a dalt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      